<template>
  <el-aside :width="isCollapse ? '64px' : '250px'" class="aside-menu">
    <div
      class="collapse-icon"
      :style="{ left: isCollapse ? '74px' : '260px' }"
      @click="
        isCollapse = !isCollapse;
        $emit('clickCollapse');
      "
    >
      <i class="el-icon-s-unfold" v-show="isCollapse"></i>
      <i class="el-icon-s-fold" v-show="!isCollapse"></i>
    </div>
    <!-- 系统logo -->
    <div class="aside-logo" :style="{ padding: isCollapse ? '' : '0 10px' }">
      <img v-if="platIconTitleData.icontitleDoorLogo" :src="asideLogoUrl + platIconTitleData.icontitleDoorLogo" />
      <img v-else src="~@/assets/image/index/null.jpg" />
      <div class="aside-title" :style="{ color: `${platIconTitleData.icontitleDoorTitleColor}` }" v-if="!isCollapse">
        {{ customTitle ? customTitle : platIconTitleData.icontitleDoorTitle }}
      </div>
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <!-- 默认展开表单项目 -->
      <el-tree :highlight-current="true" :data="menus" @node-click="menuClick" :props="defaultProps">
        <template v-slot="{ node }">
          <span class="custom-tree-node">
            <i v-if="node.data.menuPid == 'form-001'" class="el-icon-document" style="margin-left: -10px"></i>
            <i v-else class="el-icon-refresh" style="margin-left: -10px"></i>
            <span>{{ node.label }}</span>
          </span>
        </template>
      </el-tree>
      <!-- <el-menu
        :default-openeds="['form-001']"
        mode="vertical"
        :show-timeout="200"
        text-color="#fff"
        unique-opened
        :collapse="isCollapse"
        active-text-color="#fff"
        class="menu-wrap"
        ref="leftElMenu"
      >
        <menu-item @menuClick="menuClick" :menus="menus" :collapse="isCollapse"></menu-item>
      </el-menu> -->
    </el-scrollbar>
  </el-aside>
</template>

<script>
import _ from 'lodash';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
export default {
  name: 'FormAndFlowLeftMenu',
  components: {
    MenuItem: () => import('./components/menu-item')
  },
  data() {
    return {
      activeId: '',
      isCollapse: false,
      token: '',
      defaultProps: {
        children: 'children',
        label: 'menuName',
        key: 'menuId'
      }
    };
  },
  props: {
    customTitle: {
      type: String
    },
    menus: {
      type: Array
    },
    platIconTitleData: {
      type: Object,
      default: {}
    },
    asideLogoUrl: {
      type: String,
      default: `${process.env.VUE_APP_SERVER_CODE}sysFileInfo/pubdownload?fileId=`
    }
  },
  created() {
    this.token = StorageUtil.getSessionStorage('token');
  },
  mounted() {
    this.searchFormWidth();
    //监听浏览器窗口事件 防抖
    window.onresize = _.debounce(() => {
      if (!this.timer) {
        this.timer = true;
        setTimeout(() => {
          this.searchFormWidth();
          this.timer = false;
        }, 400);
      }
    }, 500);
  },
  watch: {
    // $route(to) {
    //   this.activeId = '';
    //   this.deepRecursionFindPath(this.menus, to.path);
    // }
  },
  methods: {
    //深度遍历找出当前路由对应的左侧树节点
    deepRecursionFindPath(menus = [], path) {
      menus.forEach(item => {
        if (item.menuRouterUrl === path) {
          this.activeId = item.menuId;
        } else {
          if (_.isArray(item.children) && !_.isEmpty(item.children)) {
            this.deepRecursionFindPath(item.children, path);
          }
        }
      });
    },
    //获取当前窗口宽度
    searchFormWidth() {
      let w = window.innerWidth;
      if (w <= 1200) {
        this.isCollapse = true;
      } else {
        this.isCollapse = false;
      }
    },
    menuClick(data) {
      this.$emit('menuClick', data);
    },

    // 取消选中
    cancelActive() {
      this.$refs.leftElMenu.activeIndex = null;
    }
  }
};
</script>

<style scoped lang="scss">
.aside-logo {
  margin-bottom: 2px;
  width: 100%;
  height: 60px;
  background-color: #008df7;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  img {
    width: 48px;
    height: 48px;
    margin: 0 auto;
    object-fit: contain;
  }
  .aside-title {
    width: 100%;
    height: 100%;
    line-height: 60px;
    padding-left: 10px;
    box-sizing: border-box;
    display: inline-block;
    color: #fff;
    font-size: var(--rootFontSize2);
    font-weight: 600;
    text-align: justify;
  }
  .aside-title:after {
    content: '';
    width: 100%;
    display: inline-block;
  }
}

.collapse-icon {
  font-size: 26px;
  width: 16px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  z-index: 1;
  background: transparent;
  position: fixed;
  top: 10px;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--mainThemeColor);
  transition: all 0.3s;
  color: #ffffff;
}

.el-scrollbar {
  border-right: 1px solid #ccc;
}
.el-tree {
  margin-top: 10px;
  /deep/ .el-tree-node.is-current > .el-tree-node__content {
    background-color: #afd5fd;
    .el-tree-node__label {
      color: #008df7;
    }
  }
}
/deep/ .el-tree-node {
  margin-bottom: 10px;
  .el-tree-node__label {
    font-size: 16px;
  }
  .el-tree-node__content {
    letter-spacing: 2px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
  }
}
</style>

