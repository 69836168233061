var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-aside",
    {
      staticClass: "aside-menu",
      attrs: { width: _vm.isCollapse ? "64px" : "250px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "collapse-icon",
          style: { left: _vm.isCollapse ? "74px" : "260px" },
          on: {
            click: function ($event) {
              _vm.isCollapse = !_vm.isCollapse
              _vm.$emit("clickCollapse")
            },
          },
        },
        [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCollapse,
                expression: "isCollapse",
              },
            ],
            staticClass: "el-icon-s-unfold",
          }),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCollapse,
                expression: "!isCollapse",
              },
            ],
            staticClass: "el-icon-s-fold",
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "aside-logo",
          style: { padding: _vm.isCollapse ? "" : "0 10px" },
        },
        [
          _vm.platIconTitleData.icontitleDoorLogo
            ? _c("img", {
                attrs: {
                  src:
                    _vm.asideLogoUrl + _vm.platIconTitleData.icontitleDoorLogo,
                },
              })
            : _c("img", {
                attrs: { src: require("@/assets/image/index/null.jpg") },
              }),
          !_vm.isCollapse
            ? _c(
                "div",
                {
                  staticClass: "aside-title",
                  style: {
                    color: "" + _vm.platIconTitleData.icontitleDoorTitleColor,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.customTitle
                          ? _vm.customTitle
                          : _vm.platIconTitleData.icontitleDoorTitle
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c("el-tree", {
            attrs: {
              "highlight-current": true,
              data: _vm.menus,
              props: _vm.defaultProps,
            },
            on: { "node-click": _vm.menuClick },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var node = ref.node
                  return [
                    _c("span", { staticClass: "custom-tree-node" }, [
                      node.data.menuPid == "form-001"
                        ? _c("i", {
                            staticClass: "el-icon-document",
                            staticStyle: { "margin-left": "-10px" },
                          })
                        : _c("i", {
                            staticClass: "el-icon-refresh",
                            staticStyle: { "margin-left": "-10px" },
                          }),
                      _c("span", [_vm._v(_vm._s(node.label))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }